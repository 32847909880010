import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import { COLOR_PRIMARY, COLOR_WHITE } from "../../common/Color";
import MediaQuery from "react-responsive";
class Footer extends React.Component {
  render() {
    return (
      <Fragment>
        <MediaQuery maxWidth={480}>
          <div
            style={{
              width: "100%",
              background: COLOR_PRIMARY,
              height: "290px",
              color: COLOR_WHITE,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ width: "85%" }}>
              <p
                style={{
                  fontSize: "1rem",
                  fontWeight: "500",
                  width: "100%",
                  marginBottom: "5px",
                }}
              >
                회사명 에이치전국화물 주식회사
              </p>
              <p
                style={{
                  fontSize: "1rem",
                  fontWeight: "500",
                  width: "100%",
                  marginBottom: "5px",
                }}
              >
                사업자등록번호 308-81-47359
              </p>
              <p
                style={{
                  fontSize: "1rem",
                  fontWeight: "500",
                  width: "100%",
                  marginBottom: "5px",
                }}
              >
                화물운송주선허가 제 3670000-1992-03-00005호
              </p>
              <p
                style={{
                  fontSize: "1rem",
                  fontWeight: "500",
                  width: "100%",
                  marginBottom: "5px",
                }}
              >
                <span style={{ marginRight: "3%" }}>대표번호 1833-8852</span>
                <span>이메일 logis2021@naver.com</span>
              </p>
              <p
                style={{
                  fontSize: "1rem",
                  fontWeight: "500",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "20px",
                }}
              >
                대전광역시 유성구 계룡로105번길 15, 2층 203-G12호
              </p>

              {/* <p
                style={{
                  fontSize: "1rem",
                  fontWeight: "500",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span
                  style={{ marginRight: "2%", cursor: "pointer" }}
                  onClick={() => this.props.fnRouterChage("terms")}
                >
                  이용약관
                </span>
                <span
                  style={{ marginRight: "2%", cursor: "pointer" }}
                  onClick={() => this.props.fnRouterChage("privacy")}
                >
                  개인정보 처리 방침
                </span>
                <span
                  style={{ marginRight: "2%", cursor: "pointer" }}
                  onClick={() => this.props.fnRouterChage("locationTerm")}
                >
                  위치기반 서비스 이용약관
                </span>
              </p> */}
              <p
                style={{
                  fontSize: "1rem",
                  fontWeight: "500",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                COPYRIGHT ⓒ 에이치전국화물 주식회사. All Right Reserved.
              </p>
            </div>
          </div>
        </MediaQuery>

        <MediaQuery minWidth={481} maxWidth={1249}>
          <div
            style={{
              width: "100%",
              background: COLOR_PRIMARY,
              height: "290px",
              color: COLOR_WHITE,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ width: "85%" }}>
              <p
                style={{
                  fontSize: "1.2rem",
                  fontWeight: "500",
                  width: "100%",
                  marginBottom: "5px",
                }}
              >
                회사명 에이치전국화물 주식회사
              </p>
              <p
                style={{
                  fontSize: "1.2rem",
                  fontWeight: "500",
                  width: "100%",
                  marginBottom: "5px",
                }}
              >
                사업자등록번호 308-81-47359
              </p>
              <p
                style={{
                  fontSize: "1.2rem",
                  fontWeight: "500",
                  width: "100%",
                  marginBottom: "5px",
                }}
              >
                화물운송주선허가 제 3670000-1992-03-00005호
              </p>
              <p
                style={{
                  fontSize: "1.2rem",
                  fontWeight: "500",
                  width: "100%",
                  marginBottom: "5px",
                }}
              >
                <span style={{ marginRight: "3%" }}>대표번호 1833-8852</span>
                <span>이메일 logis2021@naver.com</span>
              </p>
              <p
                style={{
                  fontSize: "1.2rem",
                  fontWeight: "500",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "20px",
                }}
              >
                대전광역시 유성구 계룡로105번길 15, 2층 203-G12호
              </p>

              {/* <p
                style={{
                  fontSize: "1.2rem",
                  fontWeight: "500",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span
                  style={{ marginRight: "2%", cursor: "pointer" }}
                  onClick={() => this.props.fnRouterChage("terms")}
                >
                  이용약관
                </span>
                <span
                  style={{ marginRight: "2%", cursor: "pointer" }}
                  onClick={() => this.props.fnRouterChage("privacy")}
                >
                  개인정보 처리 방침
                </span>
                <span
                  style={{ marginRight: "2%", cursor: "pointer" }}
                  onClick={() => this.props.fnRouterChage("locationTerm")}
                >
                  위치기반 서비스 이용약관
                </span>
              </p> */}
              <p
                style={{
                  fontSize: "1.2rem",
                  fontWeight: "500",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                COPYRIGHT ⓒ 에이치전국화물 주식회사. All Right Reserved.
              </p>
            </div>
          </div>
        </MediaQuery>

        <MediaQuery minWidth={1250}>
          <div
            style={{
              width: "100%",
              background: COLOR_PRIMARY,
              height: "11.875rem",
              color: COLOR_WHITE,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ width: "79%" }}>
              <p
                style={{
                  fontSize: "1rem",
                  fontWeight: "500",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "0px",
                }}
              >
                <span style={{ marginRight: "1%" }}>
                  회사명 에이치전국화물 주식회사
                </span>
                <span style={{ marginRight: "1%" }}>
                  사업자등록번호 308-81-47359
                </span>
                <span style={{ marginRight: "1%" }}>
                  화물운송주선허가 제 3670000-1992-03-00005호
                </span>
                <span>대표번호 1833-8852</span>
              </p>
              <p
                style={{
                  fontSize: "1rem",
                  fontWeight: "500",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "20px",
                }}
              >
                <span style={{ marginRight: "1%" }}>
                  대전광역시 유성구 계룡로105번길 15, 2층 203-G12호
                </span>
                <span style={{ marginRight: "1%" }}>
                  이메일 logis2021@naver.com
                </span>
                <span>대표자 : 박혜영</span>
              </p>
              <p
                style={{
                  fontSize: "1rem",
                  fontWeight: "500",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {/* <span
                  style={{ marginRight: "1%", cursor: "pointer" }}
                  onClick={() => this.props.fnRouterChage("terms")}
                >
                  이용약관
                </span>
                <span
                  style={{ marginRight: "1%", cursor: "pointer" }}
                  onClick={() => this.props.fnRouterChage("privacy")}
                >
                  개인정보 처리 방침
                </span>
                <span
                  style={{ marginRight: "1%", cursor: "pointer" }}
                  onClick={() => this.props.fnRouterChage("locationTerm")}
                >
                  위치기반 서비스 이용약관
                </span> */}
                <a
                  style={{ color: COLOR_WHITE, width: "8%" }}
                  href="../../form/document.hwp"
                  download="㈜에이치전국화물 정보망서비스 이용신청서.hwp"
                >
                  <span style={{ marginRight: "1%", cursor: "pointer" }}>
                    이용신청서
                  </span>
                </a>

                <span
                  style={{ marginRight: "11%", cursor: "pointer" }}
                  onClick={() => this.props.fnRouterChage("admin/login")}
                >
                  관리자 로그인
                </span>
                <span>
                  COPYRIGHT ⓒ 에이치전국화물 주식회사. All Right Reserved.
                </span>
              </p>
            </div>
          </div>
        </MediaQuery>
      </Fragment>
    );
  }
}

export default Footer;
